* {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-color: inherit;
  list-style: none;
}

.date-wrap {
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.select-fullwidth {
  width: 100% !important;
}

// Pure css Select

// .select {
//   position: relative;
//   overflow: hidden;
//   display: block;
//   //   margin: auto;
//   width: 30%;
//   height: 100%;
//   border-bottom: 4px solid #000;
//   border-radius: 0;
//   font-size: 18px;
//   font-weight: 500;
//   color: #000;
//   line-height: 0px;
//   box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);

//   .title,
//   .placeholder {
//     position: relative;
//     display: block;
//     width: 100%;
//     height: 100%;
//     padding: 1.5em 2em;
//     background: rgba(255, 255, 255, 1);
//     border-top: 1px solid rgba(0, 0, 0, 0.05);
//     cursor: pointer;
//   }
//   & > input {
//     position: absolute;
//     left: 0px;
//     top: 0px;
//     z-index: 1;
//     width: 100%;
//     height: 100%;
//     display: block;
//     opacity: 0;
//     cursor: pointer;
//     &:checked {
//       div.options label.option .title {
//         display: none !important;
//       }
//     }
//     &:not(:checked) {
//       z-index: 4;
//       ~ label.option {
//         > span.title {
//           display: none;
//         }
//       }
//     }
//     &:disabled {
//       cursor: no-drop;
//     }
//   }
//   & > span.placeholder {
//     position: relative;
//     z-index: 0;
//     display: inline-block;
//     width: 100%;
//     color: #999;
//     border-top: 0px;
//   }
//   label.option {
//     display: block;
//     overflow: hidden;
//     z-index: 1;
//     width: 100%;
//     transition: all 1s ease-out;
//     span.title {
//       position: relative;
//       z-index: 2;
//       transition: background 0.3s ease-out;
//       &:hover {
//         color: #fff;
//         background: #000;
//         box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
//       }
//     }
//     input {
//       display: none;
//       &:checked ~ span.title {
//         position: absolute;
//         display: block;
//         z-index: 3;
//         top: 0px;
//         font-size: 18px;
//         background: #fff;
//         border-top: 0px;
//         box-shadow: none;
//         color: inherit;
//         width: 100%;
//       }
//       &:disabled {
//         & ~ span.title {
//           background: #f9f9f9 !important;
//           color: #aaa;
//           &:hover {
//             color: #aaa;
//             background: none;
//             cursor: no-drop;
//           }
//         }
//       }
//     }
//   }
// }

// // Pure css Select2
// .select2 {
//   position: relative;
//   overflow: hidden;
//   display: block;
//   //   margin: auto;
//   width: 30%;
//   height: 100%;
//   border-bottom: 4px solid #000;
//   border-radius: 0;
//   font-size: 18px;
//   font-weight: 500;
//   color: #000;
//   line-height: 0px;
//   box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);

//   .title2,
//   .placeholder2 {
//     position: relative;
//     display: block;
//     width: 100%;
//     height: 100%;
//     padding: 1.5em 2em;
//     background: rgba(255, 255, 255, 1);
//     border-top: 1px solid rgba(0, 0, 0, 0.05);
//     cursor: pointer;
//   }
//   & > input {
//     position: absolute;
//     left: 0px;
//     top: 0px;
//     z-index: 1;
//     width: 100%;
//     height: 100%;
//     display: block;
//     opacity: 0;
//     cursor: pointer;
//     &:checked {
//       div.options2 label.option2 .title2 {
//         display: none !important;
//       }
//     }
//     &:not(:checked) {
//       z-index: 4;
//       ~ label.option2 {
//         > span.title2 {
//           display: none;
//         }
//       }
//     }
//     &:disabled {
//       cursor: no-drop;
//     }
//   }
//   & > span.placeholder2 {
//     position: relative;
//     z-index: 0;
//     display: inline-block;
//     width: 100%;
//     color: #999;
//     border-top: 0px;
//   }
//   label.option2 {
//     display: block;
//     overflow: hidden;
//     z-index: 1;
//     width: 100%;
//     transition: all 1s ease-out;
//     span.title2 {
//       position: relative;
//       z-index: 2;
//       transition: background 0.3s ease-out;
//       i.icon2 {
//         padding-right: 8px;
//         color: rgba(146, 168, 209, 1);
//       }
//       &:hover {
//         color: #fff;
//         background: #000;
//         box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
//       }
//     }
//     input {
//       display: none;
//       &:checked ~ span.title2 {
//         position: absolute;
//         display: block;
//         z-index: 3;
//         top: 0px;
//         font-size: 18px;
//         background: #fff;
//         border-top: 0px;
//         box-shadow: none;
//         color: inherit;
//         width: 100%;
//       }
//       &:disabled {
//         & ~ span.title2 {
//           background: #f9f9f9 !important;
//           color: #aaa;
//           &:hover {
//             color: #aaa;
//             background: none;
//             cursor: no-drop;
//           }
//         }
//       }
//     }
//   }
// }

// // Pure css Select4
// .select4 {
//   position: relative;
//   overflow: hidden;
//   display: block;
//   //   margin: auto;
//   width: 30%;
//   height: 100%;
//   border-bottom: 4px solid #000;
//   border-radius: 0;
//   font-size: 18px;
//   font-weight: 500;
//   color: #000;
//   line-height: 0px;
//   box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
//   .title4,
//   .placeholder4 {
//     position: relative;
//     display: block;
//     width: 100%;
//     height: 100%;
//     padding: 1.5em 2em;
//     background: rgba(255, 255, 255, 1);
//     border-top: 1px solid rgba(0, 0, 0, 0.05);
//     cursor: pointer;
//   }
//   & > input {
//     position: absolute;
//     left: 0px;
//     top: 0px;
//     z-index: 1;
//     width: 100%;
//     height: 100%;
//     display: block;
//     opacity: 0;
//     cursor: pointer;
//     &:checked {
//       div.options4 label.option4 .title4 {
//         display: none !important;
//       }
//     }
//     &:not(:checked) {
//       z-index: 4;
//       ~ label.option4 {
//         > span.title4 {
//           display: none;
//         }
//       }
//     }
//     &:disabled {
//       cursor: no-drop;
//     }
//   }
//   & > span.placeholder4 {
//     position: relative;
//     z-index: 0;
//     display: inline-block;
//     width: 100%;
//     color: #999;
//     border-top: 0px;
//   }
//   label.option4 {
//     display: block;
//     overflow: hidden;
//     z-index: 1;
//     width: 100%;
//     transition: all 1s ease-out;
//     span.title4 {
//       position: relative;
//       z-index: 2;
//       transition: background 0.3s ease-out;
//       &:hover {
//         color: #fff;
//         background: #000;
//         box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
//       }
//     }
//     input {
//       display: none;
//       &:checked ~ span.title4 {
//         position: absolute;
//         display: block;
//         z-index: 3;
//         top: 0px;
//         font-size: 18px;
//         background: #fff;
//         border-top: 0px;
//         box-shadow: none;
//         color: inherit;
//         width: 100%;
//       }
//       &:disabled {
//         & ~ span.title4 {
//           background: #f9f9f9 !important;
//           color: #aaa;
//           &:hover {
//             color: #aaa;
//             background: none;
//             cursor: no-drop;
//           }
//         }
//       }
//     }
//   }
// }

.form {
  width: 100%;
  height: 100%;
  padding-bottom: 75px;
  display: flex;
  justify-content: center;
  align-items: center;

  .select {
    width: 70%;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);

    &_expand {
      width: 0;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-appearance: none;
    }

    &_expandLabel {
      display: block;
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    &_close {
      display: none;
    }

    &_closeLabel {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
    }

    &_items {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 2px;
      padding-top: 40px;
      // border-bottom: 4px solid #000;
    }

    &_input {
      display: none;
    }

    &_label {
      // transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
      display: block;
      height: 0;
      font-size: 14px;
      line-height: 40px;
      overflow: hidden;
      color: #3e3e3e;
      background-color: #fff;
      cursor: pointer;
      padding-left: 20px;

      &-placeholder {
        height: 40px;
        vertical-align: middle;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.6;
        background-color: transparent;
      }
    }

    &_expand:checked {
      + .select_closeLabel {
        display: block;

        + .select_options {
          overflow-y: scroll;
          height: 90px;

          .select_label {
            height: 40px;
            &:hover {
              background-color: #f7f7f7;
            }
          }

          + .select_expandLabel {
            display: none;
          }
        }
      }
    }

    &_input:checked + .select_label {
      height: 40px;
      margin-top: -40px;
      border-bottom: 4px solid #000;
    }
  }
}

.form2 {
  width: 100%;
  height: 100%;
  padding-bottom: 75px;
  display: flex;
  justify-content: center;
  align-items: center;

  .select2 {
    width: 70%;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);

    &_expand {
      width: 0;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-appearance: none;
    }

    &_expandLabel {
      display: block;
      width: 100%;
      height: 40px;
      position: absolute;

      top: 0;
      left: 0;
      cursor: pointer;
    }

    &_close {
      display: none;
    }

    &_closeLabel {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
    }

    &_items {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 2px;
      padding-top: 40px;
      // border-bottom: 4px solid #000;
    }

    &_input {
      display: none;
    }

    &_label {
      // transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
      display: block;
      height: 0;
      font-size: 14px;
      line-height: 40px;
      overflow: hidden;
      color: #3e3e3e;
      background-color: #fff;
      cursor: pointer;
      padding-left: 20px;

      &-placeholder {
        height: 40px;
        vertical-align: middle;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.6;
        background-color: transparent;
      }
    }

    &_expand:checked {
      + .select2_closeLabel {
        display: block;
        + .select2_options {
          overflow-y: scroll;
          height: 90px;

          .select2_label {
            height: 40px;
            &:hover {
              background-color: #f7f7f7;
            }
          }

          + .select2_expandLabel {
            display: none;
          }
        }
      }
    }

    &_input:checked + .select2_label {
      height: 40px;
      margin-top: -40px;
      border-bottom: 4px solid #000;
    }
  }
}

.form3 {
  width: 100%;
  height: 100%;
  padding-bottom: 75px;
  display: flex;
  justify-content: center;
  align-items: center;

  .select3 {
    width: 70%;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);

    &_expand {
      width: 0;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-appearance: none;
    }

    &_expandLabel {
      display: block;
      width: 100%;
      height: 40px;
      position: absolute;

      top: 0;
      left: 0;
      cursor: pointer;
    }

    &_close {
      display: none;
    }

    &_closeLabel {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
    }

    &_items {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 2px;
      padding-top: 40px;
      // border-bottom: 4px solid #000;
    }

    &_input {
      display: none;
    }

    &_label {
      // transition: all 250ms cubic-bezier(0.4, 0.25, 0.3, 1);
      display: block;
      height: 0;
      font-size: 14px;
      line-height: 40px;
      overflow: hidden;
      color: #3e3e3e;
      background-color: #fff;
      cursor: pointer;
      padding-left: 20px;

      &-placeholder {
        height: 40px;
        vertical-align: middle;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.6;
        background-color: transparent;
      }
    }

    &_expand:checked {
      + .select3_closeLabel {
        display: block;

        + .select3_options {
          overflow-y: scroll;
          height: 90px;

          .select3_label {
            height: 40px;
            &:hover {
              background-color: #f7f7f7;
            }
          }

          + .select3_expandLabel {
            display: none;
          }
        }
      }
    }

    &_input:checked + .select3_label {
      height: 40px;
      margin-top: -40px;
      border-bottom: 4px solid #000;
    }
  }
}
