@import "./dropdowns.scss";
@import "./spinner.scss";

@font-face {
  font-family: "Akzidenz-grotesk Bold";
  src: url("https://use.typekit.net/af/8dd729/00000000000000000001362c/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/8dd729/00000000000000000001362c/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/8dd729/00000000000000000001362c/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Akzidenz-grotesk Regular";
  src: url("https://use.typekit.net/af/9d033e/000000000000000000013633/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9d033e/000000000000000000013633/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9d033e/000000000000000000013633/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Akzidenz-grotesk Light";
  src: url("https://use.typekit.net/af/5556ca/000000000000000000013635/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5556ca/000000000000000000013635/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5556ca/000000000000000000013635/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  font-family: "Akzidenz-grotesk Regular", sans-serif;
  height: calc(100vh - 90px);
  min-height: 97vh;
}

.sv-root-modern {
  width: 50%;
}

.results {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // height: 100%;
}

.results__head {
  background-color: #fff;
  color: #000;
  width: 90%;
  // height: 70vh;
  // height: calc(100vh - 100px);
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 10px;
}

.results__title {
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  padding-top: 60px;
  font-size: 30px;
  font-family: "Akzidenz-grotesk Bold", sans-serif;
}

.results__subtitle {
  font-size: 18px;
  margin: 10px;
  text-align: center;
  font-family: "Akzidenz-grotesk Regular", sans-serif;
}

.results__icons-wrap {
  display: flex;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 40px 0;
}

.results__icons-wrap .results__icon:last-child {
  margin: 0px auto;
}

.results__icon {
  width: 33%;
  /* height: 140px; */
  text-align: center;
}

.results__icon--card {
  /* width: 70px; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 10px 0;
  /* padding-top: 5px; */
}

.results__icon--card span {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  width: 100px;
  white-space: normal;
  height: 30px;
}

.pie-chart span {
  font-size: 12px;
  /* height: 50px; */
  width: 80px;
  margin: 0 auto;
  text-align: center;
  white-space: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.pie-chart {
  position: relative;
  /* width: 70px; */
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  /* margin: 10px; */
  z-index: 1;
}

.see-more {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.see-more span {
  font-size: 12px;
  color: #000;
  margin: 10px 0;
  font-weight: 500;
}

.results__block {
  width: 90%;
  height: 150px;
  display: flex;
  border-radius: 10px;
  margin: 20px auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.results__block--left {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 2;
}

.results__block--right {
  display: flex;
  align-items: center;
  width: 70%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}

.icon-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 125px;
  z-index: 1;
}

.icon-wrap span {
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  width: 90px;
}

.results__subtitle--mobile {
  display: none;
}

.results__subtitle--desktop {
  display: block;
}

.switcher-block-wrap {
  width: 100%;
}

.switcher {
  display: flex;
  position: relative;
  width: 90%;
  margin: 30px auto 20px;
}

.switcher__button {
  width: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  background: no-repeat;
  outline: none;
  border: none;
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Akzidenz-grotesk Regular", sans-serif;
}

.switcher__move-btn {
  background-color: #00909d;
  width: 50%;
  transition: 0.3s;
  border-radius: 15px;
  border: 0;
}

.switcher__button-food {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  position: relative;
  z-index: 9;
}

.switcher__button-body {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  position: absolute;
  right: 0px;
  z-index: 9;
}

.switcher-subtitle {
  width: 70%;
  text-align: center;
  font-size: 18px;
  margin: 0px auto 20px;
  font-weight: 500;
  font-family: "Akzidenz-grotesk Light", sans-serif;
}

.radial-chart {
  position: relative;
  display: inline-block;
  transition: all 0.3s ease-in;
}

.radial-chart.no-progress .radial-chart-progress {
  opacity: 0;
}

.radial-chart-total {
  opacity: 0.3;
  stroke-width: 4;
}

.radial-chart-progress {
  transform: rotate(-90deg);
  transform-origin: center;
  transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
  transition-delay: 0.3s;
  stroke-width: 4;
}

.results__block--right {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  color: #000;
}

.results__block--right::-webkit-scrollbar {
  display: none;
}

.wave-element {
  position: absolute;
  width: 90%;
  bottom: -15px;
  left: 50%;
  transform: translate(-50%, 0px);
}

.wave-element-card {
  position: absolute;
  height: 100%;
  z-index: 9;
}

.wave-element-card-black {
  right: -19px;
}

.wave-element-card-white {
  right: -10px;
}

.icon-svg {
  position: absolute;
  top: 35%;
  transform: translate(0px, -50%);
  width: 30px;
  height: 30px;
}

@media screen and (min-width: 1024px) {
  .results__head {
    width: 35%;
    margin: 0px;
    box-shadow: none;
  }

  .results__title {
    font-size: 48px;
    width: 60%;
  }

  .results__subtitle {
    font-size: 18px;
    width: 80%;
    margin: 25px auto 0;
  }

  .share-wrap {
    justify-content: center;
    bottom: 0;
  }

  .share-wrap span {
    margin: 10px 0px;
  }

  .results__block--right {
    justify-content: space-evenly;
    width: 80%;
  }

  .results__block--left {
    width: 20%;
  }

  .results__icon {
    width: 20%;
  }

  .see-more {
    left: 105%;
  }
  .see-more span {
    transform: translate(0, -100px) rotate(-90deg);
    width: 200px;
  }

  .share-wrap span {
    padding: 10px;
  }

  .switcher {
    width: 60%;
  }

  .profile {
    background: #00909d;
    color: #fff;
    padding: 0 20px;
  }

  .switcher-block-wrap {
    width: 65%;
    overflow-y: auto;
    // height: calc(100vh - 90px);
    max-height: 100vh;
  }

  .wave-element {
    display: none;
  }

  .sv-rating__item {
    width: 51%;
    padding: 5px 0;
  }

  .sv-title {
    font-size: 30px;
    color: #000;
    width: 90%;
  }

  .popup_inner {
    width: 40%;
    height: 40%;
    top: 40%;
  }

  .left-wave {
    width: 180px;
  }
}

@media screen and (max-width: 1023px) {
  .App {
    height: auto;
  }

  .results {
    align-items: flex-start;
    height: 100vh;
  }

  .results__subtitle--mobile {
    display: block;
  }

  .results__subtitle--desktop {
    display: none;
  }

  .share-wrap {
    width: 80%;
    justify-content: flex-end;
    bottom: 15px;
  }

  .share-wrap span {
    margin: 0px 10px 0 0;
  }

  .sv-rating__item {
    width: 100%;
  }

  .sv-title {
    font-size: 30px;
    color: #000;
  }

  .sv-rating__item-text {
    font-size: 14px !important;
  }

  .popup_inner {
    width: 80%;
    height: 200px;
    top: 20%;
  }

  .see-more {
    bottom: 0%;
  }

  .switcher__button {
    font-size: 11px;
  }

  .left-wave {
    width: 120px;
  }
}

.sv-rating__item-text {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

.sv-rating__item-text > span {
  margin: 0;
}

.sv-question .sv-selectbase {
  font-size: 14px;
}

.sv-rating {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // flex-direction: column;
  width: 100%;
}

.sv-root-modern {
  width: 85%;
}

.sv-root-modern .sv-checkbox--checked .sv-checkbox__svg {
  background-color: #00909d !important;
  fill: #00909d !important;
  border-color: #00909d !important;
}

.sv-root-modern .sv-item__control:focus + .sv-item__decorator {
  border: none;
}

.sv-root-modern .sv-checkbox--allowhover:hover .sv-checkbox__svg {
  background-color: #fff !important;
  border: 2px solid #00909d !important;
}
.sv-root-modern .sv-checkbox--allowhover:hover .sv-checkbox__svg path {
  display: none;
}

.sv-root-modern .sv-checkbox__svg {
  border-color: #000 !important;
  border-radius: 0;
  border: 2px solid #000;
}

.sv-item__decorator {
  border: none;
}

.sv-root-modern .sv-question__title--answer {
  background-color: transparent !important;
}

.sv-footer {
  margin-right: -5%;
  margin-left: -5%;
  padding: 1.2em 0 0.87em 0;
}

.sv-footer__next-btn {
  background-color: #000 !important;
  border-radius: 0;
  text-transform: uppercase;
}

.sv-footer__next-btn:hover {
  opacity: 0.8;
}

.sv-footer__prev-btn {
  background-color: transparent !important;
  color: #000 !important;
  border-radius: 0;
  border-bottom: 2px solid #00909d;
  text-transform: uppercase;
  padding: 0.5em 0em 0.3em;
}

.sv-footer__prev-btn:hover {
  opacity: 0.8;
}

.sv-progress {
  margin-top: 2em !important;
  margin-bottom: 10px;
}

.sv-progress__bar {
  background-color: #00909d !important;
}

.sv-question__content {
  margin-left: 0 !important;
}

.sv-root-modern .sv-rating__item-text {
  color: rgb(0, 0, 0) !important;
  border-width: 2px !important;
  border-style: solid;
  border-color: rgb(0, 0, 0) !important;
  border-image: initial;
}

.sv-root-modern .sv-rating__item-text:hover {
  background-color: #00909d !important;
  color: rgb(255, 255, 255) !important;
  border-color: #00909d !important;
}

.sv-root-modern .sv-rating__item--selected .sv-rating__item-text {
  background-color: #00909d !important;
  border-color: #00909d !important;
  color: rgb(255, 255, 255) !important;
  outline: none !important;
}

.sv-question__num {
  display: none;
}

.sv-question__title {
  padding: 0.25em 0em;
  font-family: "Akzidenz-grotesk Bold", sans-serif;
}

@media only screen and (min-width: 1000px) {
  .sv-row__question--small:only-child {
    max-width: 100%;
  }
}

.sv-body h3 {
  text-align: center;
}

.sv-body__page {
  margin: 0;
}

.sv-comment {
  height: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 40%;
  border-color: transparent !important;
  resize: none;
  outline-color: #000;
}

.sv-panel .sv-question__title {
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 1px;
  padding: 0;
}

.sv-panel__content {
  // display: flex !important;
}

.sv-question__header--location--top {
  margin: 10px 0 30px 0;
}

.sv-question__title span:nth-child(2) {
  display: none;
}

.sv-root-modern .sv-description {
  font-size: 18px;
  font-weight: 300;
  color: #000 !important;
}

.sv-text:focus {
  border-color: #00909d !important;
}

.sv-selectbase {
  font-size: 18px;
  font-weight: 500;
}

.sv-question__description {
  padding-left: 0;
}

.btn_results {
  color: #000;
  border: 2px solid #000;
  padding: 15px 30px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
  background: #fff;
  outline: none;
}

.btn_results:hover {
  color: #fff;
  border: 2px solid #00909d;
  background: #00909d;
}

.go_results {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.go_results {
  p {
    font-size: 30px;
    text-align: center;
    width: 80%;
    margin-bottom: 20px;
  }

  .subtitle {
    margin-bottom: 20px;
    text-align: center;
    padding: 0 20px;
  }
}

.share-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  // bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.share {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.save {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin-right: 50px;
}

.share-wrap span {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.share-wrap img {
  width: 16px;
  height: 16px;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.popup_inner {
  position: absolute;
  left: 50%;
  // top: 40%;
  margin: auto;
  background: white;
  padding: 20px 0px;
  // width: 40%;
  // height: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translate(-50%, -50%);

  .title {
    font-size: 35px;
    font-weight: 500;
    font-family: "Akzidenz-grotesk Regular", sans-serif;
  }

  .subtitle {
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    margin: 10px 0;
    width: 70%;
    font-family: "Akzidenz-grotesk Regular", sans-serif;
  }
}

.close-popup {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  img {
    width: 10px;
  }
}

.share-icons {
  display: flex;
  margin-top: 10px;
}

.share-icons button {
  margin: 15px;
  outline: none;
}

.custom-text {
  color: black;
  font-size: 25px;
  width: 90%;
  text-align: center;
  margin: 0 auto;
}

.custom-text-dropdown {
  color: black;
  font-size: 30px;
  text-align: left;
  margin-bottom: 40px;
}

.sv-imagepicker__item--checked .sv-imagepicker__image {
  background-color: #00909d !important;
  border-color: #00909d !important;
}

.custom-dropdown {
  position: relative;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sv-question {
  overflow: unset !important;
}

.sv-root-modern {
  opacity: 0.1;
  transition: 1s;
}

.sv-root-modern ::-webkit-scrollbar-thumb {
  background: #000;
}

.add-animation {
  opacity: 0.99;
  transition: 1s ease-in;
}

.copy-url {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #00909d;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  img {
    width: 20px;
  }
}

.sv-question__form-group {
  div {
    font-size: 12px;
    margin-left: 4px;
  }
  .sv-comment {
    display: none !important;
  }
}

.sv-root-modern .sv-footer__complete-btn {
  background-color: #00909d !important;
}

.custom-input {
  background: none;
  width: 350px;
  border: 2px solid #000;
  padding: 15px 10px;
  margin-bottom: 20px;
  font-size: 16px;
  outline: none;

  &:focus {
    border: 2px solid #00909d;
  }
}

.sv-progress__text {
  display: none;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  margin-right: 10px;
}

.checkbox-wrap {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 30px;
}

.left-wave {
  // width: 180px;
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
  z-index: 1;
}

#sq_134 .sv-title {
  display: none;
}

.copy-url-text {
  font-size: 14px;
  font-family: "Akzidenz-grotesk Light", sans-serif;
}
